import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BillingHistoryStatusComponent from "../../views/farm-settings-view/billing/BillingHistoryStatusComponent";

export class TransactionItemMobile extends React.Component {

    render() {
        const {object, onClick} = this.props;
        return (
            <div className="transaction-item-mobile" onClick={() => onClick(object)}>
                <div className="transaction-item-mobile-data">
                    <BillingHistoryStatusComponent value={object}/>
                    <div>
                        <div
                            className="font-weight-bold">{moment(object.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                        <div>{object.amount} {object.currencyIsoCode}</div>
                    </div>
                </div>
                <i className="fas fa-chevron-right"/>
            </div>
        );
    }

}

TransactionItemMobile.propTypes = {
    object: PropTypes.object,
    onClick: PropTypes.func
}

export default TransactionItemMobile;