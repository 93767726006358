import Ajv from "ajv";
import localize from "ajv-i18n"
import {sendCommand} from "../../IOT/device-functions/TerminalFunctions";
import _ from "lodash";

export function submit(values, dispatch, props) {
    sendCommand(values.device, values.schema.__command, values.data ? values.data.params || {} : {});
    console.log(values, props, "eh");
}

export function validate(values, props) {
    const {locale, validation} = props;

    const errors = {};
    if (!validation) {
        return errors;
    }
    const ajv = new Ajv({allErrors: true, errorMessages: true});
    const schema = values.schema;
    if (schema) {
        let data = _.get(values, "data.params") || (schema.type === "array" ? [] : {});
        const v = ajv.compile(schema);
        const valid = v(data);
        if (!valid) {
            let lang = localize[locale] ? locale : "en";
            localize[lang](v.errors);
            const _errors = _.cloneDeep(v.errors);
            for (let o of _errors) {
                //jesli nie damy czegos required to przypisuje nam to do elementu wyzej a nie do szukanego elementu
                if (o.keyword === "required") {
                    _.set(errors, `data.params${o.dataPath}.${o.params.missingProperty}`, o.message);
                } else {
                    //blad ogolny np. ilosc elementow w arrayu ma wiekszy priorytet i zwracamy go natychmiastowo
                    if (o.dataPath === "") {
                        return {_error: o.message}
                    } else {
                        _.set(errors, `data.params${o.dataPath}`, o.message);
                    }
                }
            }
            //tutaj nigdy nie powinno wejsc ale nigdy nie nie wiadomo
            if (Object.keys(errors).length === 0) {
                return {_error: "Error"}
            }
        }
    }
    return errors;

}