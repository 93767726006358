import React from "react";
import {submit, validate} from "./TerminalFormSubmit";
import {Field, FieldArray, FormSection, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledInput from "../basics/input/labeled-input/ReduxLabeledInput";
import Button from "../basics/button/Button";
import ReduxLabeledSelect from "../basics/select/labeled-select/ReduxLabeledSelect";
import {getSchema} from "../../IOT/device-functions/GatewayFunctions";
import {connect} from "react-redux";
import ReactJson from "react-json-view";
import InputAlikeContainer from "../input-alike-container/InputAlikeContainer";
import ReduxCheckbox from "../basics/checkbox/ReduxCheckbox";
import moment from "moment";
import Ajv from "ajv";
import ButtonGroup from "../basics/button/button-group/ButtonGroup";
import {cloneDeep, get, isFunction, isNumber} from "lodash";
import InfoBox from "../basics/info-box/InfoBox";
import Card from "../basics/card/Card";
import {withTranslation} from "react-i18next";

const FormName = "terminal-form";

class TerminalForm extends React.Component {

    constructor(props) {
        super(props);
        const {initialize, devices} = this.props;
        this.state = {
            schemas: [],
            schemaForm: null,
            options: TerminalForm.getOptions(this.props),
            devices: devices

        };
        initialize({
            validation: true,
            schema: null,
            data: {
                params: {}
            },

        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.devices !== prevState.devices) {
            return {
                options: TerminalForm.getOptions(nextProps),
                devices: nextProps.devices
            }
        }
        return null
    }


    static getOptions = (props) => {
        return props.devices.map(dev => ({
            name: isFunction(dev.getSelectName) ? dev.getSelectName() : dev.Name,
            value: dev
        }));
    };

    getOneOfParams = (schema, params, oneOfSelectedOverride) => {
        const result = {
            params: cloneDeep(params),
            oneOfOptions: [],
            oneOfSelected: [],
            schema: cloneDeep(schema)
        }
        try {
            const oneOfOptions = schema.oneOf.filter(o => Array.isArray(o.required)).map(o => ({
                name: o.label || JSON.stringify(o.required),
                value: o.required
            }))
            if (oneOfOptions.length) {
                result.oneOfOptions = oneOfOptions;
                result.oneOfSelected = oneOfSelectedOverride || oneOfOptions[0].value;
                Object.keys(result.params).forEach(key => {
                    if (!result.oneOfSelected.includes(key)) {
                        delete result.params[key];
                    }
                })
                Object.keys(result.schema.properties).forEach(key => {
                    if (!result.oneOfSelected.includes(key)) {
                        delete result.schema.properties[key];
                    }
                })
            }
        } catch (err) {
            console.error(err)
        }
        return result;
    }

    changeSchema = (schema, oneOfSelectedOverride = null) => {
        const {initialize, device, validation} = this.props;
        let tmp = schema || null;
        let params = {};
        if (tmp) {
            //wciskamy default na nullach undefinach i pustych stringach
            const ajv = new Ajv({useDefaults: "empty"});
            const v = ajv.compile(schema);
            let data = schema.type === "array" ? [] : {};
            v(data);
            params = data;
        }
        const {params: paramsFiltered, oneOfOptions, oneOfSelected, schema: schemaFiltered} = this.getOneOfParams(schema, params, oneOfSelectedOverride);
        initialize({
            schema: schema || null,
            device: device,
            oneOfOptions,
            oneOfSelected: oneOfSelectedOverride || oneOfSelected,
            data: {
                params: paramsFiltered
            },
            validation: !!validation
        })
        this.generateSchemaForm(schemaFiltered);
    };

    generateSchemaForm = (schema = {}) => {
        this.setState({
            schemaForm: this.renderInputs(schema || {})
        })
    }

    changeDevice = (val) => {
        const {initialize, device, validation} = this.props;
        if (val) {
            if ((get(val, 'DevType') !== get(device, 'DevType')) || (get(val, 'GatewayID') !== get(device, 'GatewayID')) || !this.state.schemas.length) {
                getSchema(val, val.DevType, this.clearSchemas, this.loadSchemas, this.clearSchemas)
            }
        }
        initialize({
            schema: null,
            device: val || null,
            oneOf: null,
            data: {
                params: {}
            },
            validation: !!validation
        });
        this.generateSchemaForm();
    };
    loadSchemas = (msg) => {
        const schemas = msg.CAnsw.schema || {};
        this.setState({
            schemas: Object.keys(schemas).map(key => ({
                value: {...schemas[key], __command: key},
                name: `${key} - ${schemas[key].info || ""}`
            }))
        })
    };

    clearSchemas = () => {
        const {change} = this.props;
        this.setState({
            schemas: []
        });
        change('schema', null);
        this.generateSchemaForm();
    };

    getComponent = (schema) => {
        let type = schema.type;
        const {validation} = this.props;
        if (validation && schema.enum) {
            return ReduxLabeledSelect;
        }
        if (type === "boolean") {
            return ReduxCheckbox;
        } else {
            return ReduxLabeledInput;
        }
    };

    getType = (schema) => {
        let type = schema.inputType || schema.type;
        if (type === "date") {
            type = "datetime-local"; //date jest zbugowany z momentem
        }
        return type;
    }

    format = (schema) => {
        let type = this.getType(schema);
        if (type === "datetime-local") {
            return (value) => isFinite(value) ? moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL) : ""
        } else {
            return undefined;
        }
    };

    parse = (schema) => {
        let type = this.getType(schema);
        if (schema.enum) {
            return undefined;
        }
        switch (type) {
            case "number":
                return (value) => isNaN(parseFloat(value)) ? null : parseFloat(value);
            case "integer":
                return (value) => isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
            case "datetime-local":
                return (value) => value ? +moment(value, moment.HTML5_FMT.DATETIME_LOCAL) : null;
            default:
                return undefined;
        }
    };

    options = (schema) => {
        if (schema.enum) {
            return schema.enum.map(o => ({
                name: o,
                value: o
            }))
        }
    };

    renderInputs = (schema, path = "params", isArray = false) => {
        let inputs = [];
        if (schema.type === "array") {
            inputs.push(<FieldArray name={path} key={`array_${path}`}
                                    component={({fields, meta: {touched, error, submitFailed}}) => (
                                        <>
                                            {
                                                error && <InfoBox boxColor={"error"}>{error}</InfoBox>
                                            }
                                            <label>{`${path} (${[schema.inputType, schema.type].filter(o => o).join(" > ")}) - ${schema.info || ""}`}</label>
                                            <ButtonGroup>
                                                <Button buttonColor={"info"} type="button"
                                                        icon={<i className="fas fa-plus"/>}
                                                        onClick={() => fields.push({})}/>
                                                <Button buttonColor={"secondary"} type="button"
                                                        icon={<i className="fas fa-minus"/>}
                                                        onClick={() => fields.remove(fields.length - 1)}/>
                                            </ButtonGroup>
                                            {fields.map((field, index) => (
                                                <Card key={index} colorDensity={index % 2 === 0 ? 300 : 500}>
                                                    {this.renderInputs(schema.items, `${field}`, true)}
                                                </Card>
                                            ))}
                                        </>
                                    )}/>)
        } else if (schema.type === "object") {
            for (let key of Object.keys(schema.properties || {})) {
                inputs = [...inputs, this.renderInputs(schema.properties[key], path ? path + "." + key : key)]
            }
        } else if (["number", "integer", "string", "boolean"].includes(schema.type)) {
            let type = this.getType(schema);
            inputs.push(<Field
                name={path}
                id={path}
                type={type}
                format={this.format(schema)}
                parse={this.parse(schema)}
                component={this.getComponent(schema)}
                options={this.options(schema)}
                label={`${path} (${[schema.inputType, schema.type].filter(o => o).join("/")}) - ${schema.info || ""}`}
                key={path}
            />)
        }

        return inputs;
    };

    /**
     * funkcja do parsowania wartosci liczbowych na clicku w element react json view i pokazywaniu ich na calym ekranie
     * @param name
     * @param namescape
     * @param type
     * @param value
     */
    showParsed = ({name, namescape, type, value}) => {
        clearTimeout(this.timer);
        let parsed = null;
        if (isNumber(value)) {
            parsed = (
                <Card colorDensity={200} className="position-fixed fixed-bottom text-center shadow-big float-right">
                    <div>DEC (10): {value.toString(10)}</div>
                    <div>HEX (16): {value.toString(16)}</div>
                    <div>BIN (2): {value.toString(2)}</div>
                    <div>DATE: {moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>
                    <div>DATE (UTC): {moment.utc(value).format("DD.MM.YYYY HH:mm:ss")}</div>
                </Card>
            )
        }
        this.setState({parsed: parsed});
        this.timer = setTimeout(() => {
            this.setState({parsed: ''})
        }, 7500)
    };

    render() {
        const {handleSubmit, schema, device, t, terminal, dispatch, error, oneOfOptions = []} = this.props;
        const {schemas, schemaForm, parsed, options} = this.state;

        return (
            <div className={"terminal-form"}>
                <Field
                    name={"device"}
                    id={"device"}
                    label={t("device")}
                    component={ReduxLabeledSelect}
                    options={options}
                    onChange={(val) => this.changeDevice(val)}
                />
                <Field
                    name={"schema"}
                    id={"schema"}
                    label={t("terminalView.terminalForm.commandSchema")}
                    onChange={(val) => this.changeSchema(val)}
                    options={schemas}
                    component={ReduxLabeledSelect}

                />
                {
                    schema &&
                    <>
                        <hr/>
                        <label>{t("terminalView.terminalForm.input")}</label>
                    </>
                }
                <form onSubmit={handleSubmit} noValidate>
                    {
                        !!oneOfOptions.length &&
                        <Field
                            name={"oneOfSelected"}
                            id={"oneOfSelected"}
                            label={t("terminalView.terminalForm.oneOfSelector")}
                            onChange={(val) => this.changeSchema(schema, val)}
                            options={oneOfOptions}
                            component={ReduxLabeledSelect}

                        />
                    }
                    <FormSection name={"data"}>
                        {
                            error && <InfoBox boxColor={"error"}>{error}</InfoBox>
                        }
                        {schemaForm}
                    </FormSection>
                    <ButtonGroup className="float-right">
                        <Button
                            icon={<i className="fas fa-eraser"/>}
                            type={"button"}
                            onClick={() => dispatch({type: "TERMINAL_CLEAR_MESSAGES"})}
                        >
                            {t("terminalView.terminalForm.clearTerminal")}
                        </Button>
                        <Button
                            disabled={!schema}
                            icon={<i className="fas fa-paper-plane fa-fw"/>}
                            buttonColor={"primary"}
                        >
                            {t("send")}
                        </Button>
                    </ButtonGroup>
                    <Field
                        name={"validation"}
                        id={"validation"}
                        label={t("terminalView.terminalForm.validation")}
                        component={ReduxCheckbox}
                    />
                </form>
                {parsed}
                {
                    device &&
                    <>
                        <hr/>
                        <label>{t("terminalView.terminalForm.output")}</label>
                        <InputAlikeContainer>
                            <ReactJson
                                src={terminal.filter(o => o && Object.keys(o)[0].includes(device.GatewayID))}
                                name={' '} onSelect={this.showParsed} collapsed={4} theme={{
                                base00: " ",
                                base01: "#000000",
                                base02: "#000000",
                                base03: "#000000",
                                base04: "purple",
                                base05: "#000000",
                                base06: "#000000",
                                base07: "#000000",
                                base08: "#000000",
                                base09: "#000000",
                                base0A: "#000000",
                                base0B: "#ffc007",
                                base0C: "#000000",
                                base0D: "#33cc33",
                                base0E: "#fd3b60",
                                base0F: "#00afff"
                            }}/>
                        </InputAlikeContainer>

                    </>
                }
            </div>


        );
    }
}

TerminalForm = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate,
    touchOnBlur: true,
    touchOnChange: true
})(TerminalForm);
const selector = formValueSelector(FormName);

TerminalForm = connect(state => ({
    farm: state.location.farm,
    schema: selector(state, 'schema'),
    device: selector(state, 'device'),
    validation: selector(state, 'validation'),
    oneOfOptions: selector(state, 'oneOfOptions'),
    terminal: state.terminal.messages,
    locale: state.language.locale,
    devices: state.farmDevices.devices
}))(TerminalForm);


export default withTranslation()(TerminalForm);
