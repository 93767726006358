import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {cloneDeep, findIndex, get, isEmpty, isEqual} from "lodash";
import "./_role-picker.scss"
import {withTranslation} from "react-i18next";
import Role from "./role/Role";
import {_Roles} from "../../../constans/roles";
import * as UserTypes from "validators-schema/Api/constants/userTypes";
import PlacementsCustomRoleModal from "../../modals-new/placements-custom-role-modal/PlacementsCustomRoleModal";
import Switch from "../../basics/switch/Switch";

class RolePicker extends React.Component {

    constructor(props) {
        super(props);
        const roles = this.getAllRoles(this.props);
        this.state = {
            roles: roles,
            isPrivilege: false
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.value, this.props.value)) {
            const roles = this.getAllRoles(this.props);
            this.setState({
                roles: roles,
            });
        }
        if (prevProps.farm !== this.props.farm) {
            const roles = this.getAllRoles(this.props);
            const {value, farm} = this.props;
            const isPrivilege = !!value.find((role) => role.LocalRights.find((lr) => lr.FarmID === farm));
            this.setState({
                roles: roles,
                isPrivilege,
            }, () => this.checkCurrentRoles(this.props));
        }

        if (prevProps.selectedUserType !== this.props.selectedUserType && this.state.isPrivilege) {
            this.onPrivilegeChange(true);
        }
    }

    /**
     * funkcja inicjalizująca role po otworciu modala i sprawdzeniu ról jakie zostaly przypisane użytkownikowi po stworzeniu
     * @param props
     */
    checkCurrentRoles = (props) => {
        const {value, farm} = props;
        const {isPrivilege} = this.state;
        if (!isEmpty(value) && isPrivilege) {
            //biore ferme tylko ktora mnie interesuje
            let roles = value.filter((role) => role.LocalRights.find((lr) => lr.FarmID === farm));
            const getType = (plcmnts) => plcmnts.length === 0 ? 1 : plcmnts[0] === "*" ? 2 : 3;
            if (!isEmpty(roles)) {
                roles.forEach((role) => {
                    const isPlcmnts = Array.isArray(role.LocalRights.Plcmnts);
                    roles = this.setRole(value, role.Role, true, isPlcmnts ? "Plcmnts" : undefined, isPlcmnts ? role.LocalRights.Plcmnts : [], isPlcmnts ? getType(role.LocalRights.Plcmnts) : 2);
                })
            }
        }
    };

    /**
     * funkcja zwracająca unikatowe role
     * @param props
     * @returns {{pigletCage: {Plcmnts, type}, reports: {Plcmnts, type}, chain: {Plcmnts, type}, climate: {Plcmnts, type}, counter: {Plcmnts, type}, silo: {Plcmnts, type}, users: {Plcmnts, type}, billing: {Plcmnts, type}, general: {Plcmnts, type}, cage: {Plcmnts, type}, feeding: {Plcmnts, type}, config: {Plcmnts, type}, breeding: {Plcmnts, type}}}
     */
    getAllRoles = (props) => {
        return {
            general: this.getRoles(props, _Roles.GENERAL_READ),
            breeding: this.getRoles(props, _Roles.BREEDING),
            cage: this.getRoles(props, _Roles.DEVICE_CAGE, "Plcmnts"),
            silo: this.getRoles(props, _Roles.DEVICE_SILO, "Plcmnts"),
            config: this.getRoles(props, _Roles.DEVICE_CONFIG),
            reports: this.getRoles(props, _Roles.REPORTS),
            feeding: this.getRoles(props, _Roles.DEVICE_FEEDING, "Plcmnts"),
            climate: this.getRoles(props, _Roles.DEVICE_CLIMATE, "Plcmnts"),
            chain: this.getRoles(props, _Roles.DEVICE_CHAIN, "Plcmnts"),
            billing: this.getRoles(props, _Roles.BILLING),
            pigletCage: this.getRoles(props, _Roles.DEVICE_PIGLET_CAGE, "Plcmnts"),
            counter: this.getRoles(props, _Roles.DEVICE_COUNTER, "Plcmnts"),
            users: this.getRoles(props, _Roles.USER_MANAGE)
        }
    };

    /**
     * Funkcja zwracająca role dla podanej nazwy roli
     * @param props
     * @param roleName - nazwa szukanej roli
     * @returns {{FarmID: string, PlcmntID: array}}
     */
    findRole = (props, roleName) => {
        const {value, farm} = props;
        let role = value.find(role => role.Role === roleName);
        if (role) {
            role = role.LocalRights.find(right => right.FarmID === farm);
        }
        return role;
    };


    /**
     * funkcja walidująca czy rola już istnieje oraz ustawiająca jej odpowiedni typ
     * @param props
     * @param roleName
     * @param itemsKey
     * @returns {{Plcmnts: Array, type: number}|{Plcmnts: *, type: number}}
     */
    getRoles = (props, roleName, itemsKey) => {
        let type = 0;
        if (!itemsKey) {
            if (roleName === _Roles.REPORTS) {
                let read = this.findRole(props, _Roles.REPORT_READ);
                let write = this.findRole(props, _Roles.REPORT_WRITE);
                if (read) type = 1;
                else if (write) type = 2;
            } else {
                let isActive = this.findRole(props, roleName);
                if (isActive) type = 2;
            }
            return {
                type,
                Plcmnts: []
            }
        } else {
            const role = this.findRole(props, roleName);
            const placements = get(role, itemsKey, []);
            if (!isEmpty(placements) && placements[0] === "*") { // gwiazdka oznacza dostep do wszystkich lokalizacji
                type = 2;
            }
            else if (!isEmpty(placements) && placements[0] !== "*") { // jesli pierwszym elementem nie jest gwiazdka oznacza to ze jest nim PlcmntID
                type = 3;
            }
            return {
                type,
                Plcmnts: placements
            }
        }
    };

    /**
     * funkcja wpychająca role oraz zwraca tylko te ktore zawieraja jakikolwiek farmID
     * @param allRoles
     * @param name
     * @param active
     * @param itemKey
     * @param tab
     * @param type
     * @returns {*|Array}
     */
    setRole(allRoles, name, active, itemKey, tab = [], type) {
        const {farm} = this.props;
        let roles = cloneDeep(allRoles) || [];
        let roleIndex = findIndex(roles, (role) => role.Role === name);
        let role = roleIndex > -1 ? cloneDeep(roles[roleIndex]) : {
            Role: name,
            LocalRights: []
        };
        let index = findIndex(role.LocalRights, (o) => o.FarmID === farm);
        if (index >= 0) {
            if (active) {
                if (itemKey) {
                    role.LocalRights[index][itemKey] = tab;
                }
            } else {
                role.LocalRights.splice(index, 1);
            }
        } else if (active) {
            let tmp = {
                FarmID: farm
            };
            if (itemKey) {
                tmp[itemKey] = tab;
            }
            role.LocalRights.push(tmp);
        }
        if (roleIndex > -1) {
            roles[roleIndex] = role;
        } else {
            roles.push(role);
        }

        if ((name === _Roles.REPORT_READ || name === _Roles.REPORT_WRITE) && (type === 1 || type === 2)) {
            const deleteName = name === _Roles.REPORT_READ ? _Roles.REPORT_WRITE : _Roles.REPORT_READ;
            let roleToDeleteIndex = findIndex(roles, (role) => role.Role === deleteName);
            if (roleToDeleteIndex >= 0) {
                let farmIndex = findIndex(roles[roleToDeleteIndex].LocalRights, (lr) => lr.FarmID === farm);
                if (farmIndex >= 0) {
                    roles[roleToDeleteIndex].LocalRights.splice(farmIndex, 1);
                }
            }
        }

        roles = roles.filter((role => role.LocalRights.length));
        return roles;
    }

    onRoleChange = ({type, farms, itemKey, name}) => {
        const {value, onChange} = this.props;
        let roles = cloneDeep(value);
        if (name === _Roles.REPORTS) {
            if (type === 1 || type === 0) roles = this.setRole(roles, `${name}_READ`, type > 0, itemKey, farms, type);
            if (type === 2 || type === 0) roles = this.setRole(roles, `${name}_WRITE`, type > 1, itemKey, farms, type);
        } else {
            roles = this.setRole(roles, name, type > 1, itemKey, farms, type);
        }
        onChange(roles);
    };

    onPrivilegeChange = (value) => {
        this.setState({
            isPrivilege: value
        }, () => {
            const {isPrivilege} = this.state;
            const {value, farm, onChange, selectedUserType} = this.props;
            if (!isPrivilege && !isEmpty(value)) { // zabiermy wszystkie uprawnienia do danej fermy
                const filtered = value.map((role) => {
                    const LocalRights = role.LocalRights.filter((lr) => lr.FarmID !== farm);
                    return {
                        ...role,
                        LocalRights
                    };
                }).filter((role) => role.LocalRights.length);
                onChange(filtered);
            } else if (isPrivilege) {
                let roles = cloneDeep(value);
                roles = this.setRole(roles, _Roles.GENERAL_READ, true, undefined, [], 2);
                const isManager = selectedUserType === UserTypes.MANAGER;
                const roleType = isManager ? 2 : 0;
                roles = this.setRole(roles, _Roles.BREEDING, isManager, undefined, [], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_CAGE, isManager, "Plcmnts", ["*"], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_SILO, isManager, "Plcmnts", ["*"], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_CONFIG, isManager, undefined, [], roleType);
                roles = this.setRole(roles, _Roles.REPORT_READ, isManager, undefined, [], isManager ? 1 : 0);
                roles = this.setRole(roles, _Roles.REPORT_WRITE, isManager, undefined, [], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_FEEDING, isManager, "Plcmnts", ["*"], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_CLIMATE, isManager, "Plcmnts", ["*"], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_CHAIN, isManager, "Plcmnts", ["*"], roleType);
                roles = this.setRole(roles, _Roles.BUILDING, isManager, undefined, [], roleType); // na ten moment wysylamy ale byc moze tylko serwis bedzie mial te role
                roles = this.setRole(roles, _Roles.DEVICE_PIGLET_CAGE, isManager, "Plcmnts", ["*"], roleType);
                roles = this.setRole(roles, _Roles.DEVICE_COUNTER, isManager, "Plcmnts", ["*"], roleType);
                if (!isManager) {
                    roles = this.setRole(roles, _Roles.BILLING, false, undefined, [], 0);
                    roles = this.setRole(roles, _Roles.USER_MANAGE, false, undefined, [], 0);
                }
                onChange(roles);
            }
        });
    };

    render() {
        const {roles: {general, breeding, cage, silo, config, reports, feeding, climate, chain, billing, pigletCage, counter, users}, isPrivilege} = this.state;
        const {farm, t, selectedUserType, user} = this.props;
        const isUserHasRole = (roleName) => selectedUserType === UserTypes.MANAGER ? !!user.Roles.find((role) => role.Role === roleName && !!role.LocalRights.find((lr) => lr.FarmID === farm)) : false;
        const creatorUserType = user.UserType;
        const isManager = selectedUserType === UserTypes.MANAGER;
        const privilegeItems = [
            {
                header: t("userRolePicker.general"),
                field: _Roles.GENERAL_READ,
                options: [],
                role: general,
                hasRole: true,
            },
            {
                header: t("breeding"),
                field: _Roles.BREEDING,
                options: isManager ? [] : [0, 2],
                role: breeding,
                hasRole: true,
            },
            {
                header: t("userRolePicker.cages"),
                field: _Roles.DEVICE_CAGE,
                options: isManager ? [] : [0, 2, 3],
                role: cage,
                itemKey: "Plcmnts",
                hasRole: true,
            },
            {
                header: t("weights"),
                field: _Roles.DEVICE_SILO,
                options: isManager ? [] : [0, 2, 3],
                role: silo,
                itemKey: "Plcmnts",
                hasRole: true,
            },
            {
                header: t("userRolePicker.configuration"),
                field: _Roles.DEVICE_CONFIG,
                options: isManager ? [] : [0, 2],
                role: config,
                hasRole: true,
            },
            {
                header: t("reports"),
                field: _Roles.REPORTS,
                options: isManager ? [] : [0, 1, 2],
                role: reports,
                hasRole: true,
            },
            {
                header: t("feeding"),
                field: _Roles.DEVICE_FEEDING,
                options: isManager ? [] : [0, 2, 3],
                role: feeding,
                itemKey: "Plcmnts",
                hasRole: true,
            },
            {
                header: t("climate"),
                field: _Roles.DEVICE_CLIMATE,
                options: isManager ? [] : [0, 2, 3],
                role: climate,
                itemKey: "Plcmnts",
                hasRole: true,
            },
            {
                header: t("userRolePicker.chains"),
                field: _Roles.DEVICE_CHAIN,
                options: isManager ? [] : [0, 2, 3],
                role: chain,
                itemKey: "Plcmnts",
                hasRole: true,
            },
            {
                header: t("billing"),
                field: _Roles.BILLING,
                options: isManager ? [0, 2] : [],
                role: billing,
                managerOption: true,
                hasRole: isUserHasRole(_Roles.BILLING) && creatorUserType === UserTypes.OWNER
            },
            {
                header: t("userRolePicker.userManage"),
                field: _Roles.USER_MANAGE,
                options: isManager ? [0, 2] : [],
                role: users,
                managerOption: true,
                hasRole: isUserHasRole(_Roles.USER_MANAGE)
            },
            {
                header: t("userRolePicker.smallCages"),
                field: _Roles.DEVICE_PIGLET_CAGE,
                options: isManager ? [] : [0, 2, 3],
                role: pigletCage,
                itemKey: "Plcmnts",
                hasRole: true,
            },
            {
                header: t("userRolePicker.counters"),
                field: _Roles.DEVICE_COUNTER,
                options: isManager ? [] : [0, 2, 3],
                role: counter,
                itemKey: "Plcmnts",
                hasRole: true
            }
        ].filter((o) => isManager && o.hasRole ? o : !o.managerOption);
        return (
            <div className={"fetura-role-picker"}>
                <Switch value={isPrivilege} label={isPrivilege ? t("userRolePicker.turnOffPrivilege") : t("userRolePicker.turnOnPrivilege")} onChange={(value) => this.onPrivilegeChange(value)}/>
                {
                    <hr/> && privilegeItems.filter(o => !isEmpty(o.options)).map((item, index) => (
                        <Role key={index} isPrivilege={isPrivilege} currentFarm={farm} onChange={this.onRoleChange} header={item.header} options={item.options}
                              field={item.field} {...item.role} itemKey={item.itemKey}/>
                    ))
                }
                <PlacementsCustomRoleModal/>
            </div>
        );
    }

}

RolePicker = connect((state, ownProps) => ({
    farm: ownProps.farm || state.location.farm,
    user: state.user.user,
}))(RolePicker);

RolePicker.propTypes = {
    value: PropTypes.array.isRequired
};

RolePicker.defaultProps = {
    onChange: () => {
    },
    value: []
};

export default withTranslation()(RolePicker)
