import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactJoyride from "react-joyride";
import {withTranslation} from "react-i18next";
import {getColorByName} from "../../utils/ColorUtils";

class StyledJoyride extends Component {


    getStyles = () => {
        const computedStyles = window.getComputedStyle(document.getElementsByClassName("fetura-card")[0] || document.getElementById("root"));
        return {
            arrowColor: computedStyles.getPropertyValue("background-color"),
            backgroundColor: computedStyles.getPropertyValue("background-color"),
            overlayColor: 'rgba(0, 0, 0, 0.4)',
            primaryColor: getColorByName("primary"),
            textColor: computedStyles.getPropertyValue("color"),
            zIndex: 1200
        }
    }

    render() {
        const {t, showSkipButton, autoStart, callback, continuous, debug, getHelpers, run, scrollOffset, scrollToFirstStep, stepIndex, steps} = this.props;
        return (
            <ReactJoyride
                callback={callback}
                continuous={continuous}
                debug={debug}
                autoStart={autoStart}
                getHelpers={getHelpers}
                run={run}
                showSkipButton={showSkipButton}
                scrollOffset={scrollOffset}
                scrollToFirstStep={scrollToFirstStep}
                stepIndex={stepIndex}
                steps={steps}
                styles={{
                    options: this.getStyles()
                }}
                locale={{
                    back: t("back"),
                    close: t("close"),
                    last: t("last"),
                    next: t("next"),
                    skip: t("skip")
                }}
            />
        );
    }
}

StyledJoyride.propTypes = {
    callback: PropTypes.func,
    continuous: PropTypes.bool,
    debug: PropTypes.bool,
    getHelpers: PropTypes.func,
    run: PropTypes.bool,
    scrollOffset: PropTypes.number,
    scrollToFirstStep: PropTypes.bool,
    stepIndex: PropTypes.number,
    steps: PropTypes.array.isRequired,
    showSkipButton: PropTypes.bool
};

export default withTranslation()(StyledJoyride);
