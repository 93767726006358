import React, {Component} from "react";
import {withCookies} from "react-cookie";
import {isMobile} from "../../utils/MobileUtils";
import {setBackgroundColorInMeta} from "../../utils/ColorUtils";

class ThemeProvider extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name: undefined
        };
    }

    getTheme(props) {
        const {cookies} = props;
        let mobile = isMobile() ? " mobile" : "";
        let name = cookies.get('theme') || "normal";
        if (name !== this.state.name) {
            this.setState({name: name || "normal"});
            try {
                document.body.className = `theme theme-${name}${mobile}`;
            } catch (e) {
                console.error(e);
            }
            setBackgroundColorInMeta();
        }
    }

    componentDidMount() {
        this.getTheme(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.getTheme(nextProps);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.name !== this.state.name;
    }

    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

export const _ThemeProvider = ThemeProvider;

ThemeProvider = withCookies(ThemeProvider);
export default ThemeProvider;