import React from "react";
import RolePicker from "./RolePicker";


export default class ReduxRolePicker extends React.Component {

    render() {
        const {input: {value, onChange}, farm, selectedUserType} = this.props;
        console.log("ACTUAL VALUE", value);
        console.log("SELECTED FARM", farm);
        return (
            <RolePicker selectedUserType={selectedUserType} value={value || []} onChange={onChange} farm={farm}/>
        )
    }
}
