import React from "react";
import TableGrid from "../basics/table-grid/TableGrid";
import {sortAsNumber, sortDateStrings} from "../../utils/SortUtils";
import BillingHistoryStatusComponent from "../../views/farm-settings-view/billing/BillingHistoryStatusComponent";
import moment from "moment";
import {connect} from "react-redux";
import TransactionItemMobile from "./TransactionItemMobile";
import "./_transaction-history.scss"
import TransactionMobileSelectedItem from "./TransactionMobileSelectedItem";
import {withTranslation} from "react-i18next";
import BillingInvoiceComponent from "../../views/farm-settings-view/billing/BillingInvoiceComponent";
import {get} from "lodash";

export class TransactionHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            selected: null
        }
    }

    dateValueFormatter = value => {
        return moment(value).format("DD.MM.YYYY HH:mm");
    };

    maskedNumberValueFormatter = value => {
        return `${value.substr(0, 4)} ${value.substr(4, 4)} ${value.substr(8, 4)} ${value.substr(12, 4)}`
    };

    subscriptionValueFormatter = value => {
        return `${moment(value.billingPeriodStartDate).format("DD.MM.YYYY")} - ${moment(value.billingPeriodEndDate).format("DD.MM.YYYY")}`
    };

    amountValueFormatter = value => {
        return `${value.amount} ${value.currencyIsoCode}`;
    };

    sortAmount = (a, b, header, sortType) => {
        let val1 = a.amount, val2 = b.amount;
        return sortAsNumber(a, b, header, sortType, val1, val2);
    };

    sortStatus = (a, b, header, sortType) => {
        let val1 = a.status === "settled", val2 = b.status === "settled";
        return sortAsNumber(a, b, header, sortType, val1, val2);
    };

    sortPeriod = (a, b, header, sortType, value1, value2) => {
        let val1 = value1.billingPeriodStartDate, val2 = value2.billingPeriodStartDate;
        return sortDateStrings(a, b, header, sortType, val1, val2);
    };

    farmValueFormatter = (subscriptionId) => {
        // todo - pobierac subskrypcje jesli sa puste oraz pobrac tez fermy usuniete
        const {subscriptions} = this.props;
        const sub = subscriptions.find((s) => s.id === subscriptionId);
        return get(sub, "farmName", "-");
    };

    render() {
        const {transactions, t} = this.props;
        const headers = [
            {
                name: "Ferma",
                field: "subscriptionId",
                valueFormatter: this.farmValueFormatter,
                customSort: sortDateStrings
            },
            {
                name: t("date"),
                field: "createdAt",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings
            },
            {
                name: t("sum"),
                customSort: this.sortAmount,
                valueFormatter: this.amountValueFormatter
            },
            {
                name: t("cardNumber"),
                field: "creditCard.maskedNumber",
                valueFormatter: this.maskedNumberValueFormatter
            },
            {
                name: t("farmSettings.transactionHistory.period"),
                field: "subscription",
                valueFormatter: this.subscriptionValueFormatter,
                customSort: this.sortPeriod
            },
            {
                name: t("status"),
                component: <BillingHistoryStatusComponent/>,
                customSort: this.sortStatus
            },
            {
                name: "Faktura",
                component: <BillingInvoiceComponent/>
            }
        ];
        return (
            <TableGrid data={transactions} headers={headers} shouldIndex showPagination
                       paginationItems={20} mobileRow={<TransactionItemMobile/>}
                       mobileAdditionalInfo={<TransactionMobileSelectedItem/>}
                       mobileAdditionalInfoName={t("farmSettings.transactionHistory.name")}/>
        );
    }

}

TransactionHistory = connect(state => ({
    transactions: state.braintree.transactions,
    subscriptions: state.braintree.subscriptionList,
}))(TransactionHistory);

export default withTranslation()(TransactionHistory);
