import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as PlacementsCustomRoleModal} from "../../../modals-new/placements-custom-role-modal/PlacementsCustomRoleModal";
import Button from "../../../basics/button/Button";
import {withTranslation} from "react-i18next";

class Role extends React.Component {

    onChange = (value, farms) => {
        const {itemKey, field} = this.props;
        let ids = farms || [];
        if (itemKey) {
            if (value === 0) ids = [];
            else if (value === 2) ids = ["*"];
        }
        this.props.onChange({type: value, farms: ids, itemKey: itemKey, name: field});
    };


    openModal = () => {
        const {currentFarm, Plcmnts} = this.props;
        this.props.show(PlacementsCustomRoleModal, {
            farm: currentFarm,
            initialPlacements: Plcmnts,
            onModalSubmit: (value, plcmnts) => this.onChange(value, plcmnts)
        });
    };


    renderItem = (label, onClicked, value, icon, isDisabled) => (
        <Button disabled={isDisabled} className={"option"} type={"button"} icon={icon} buttonStyle={"text"} onClick={() => onClicked()}
                buttonColor={isDisabled ? "" : this.props.type === value ? (value ? (value !== 1 ? "success" : "info") : "error") : ""}>
            {label}
        </Button>
    );

    getOptions = (options = []) => {
        const result = [];
        const {t, isPrivilege} = this.props;
        options.forEach((o) => {
            switch (o) {
                case 0:
                    result.push(this.renderItem(t("userRolePicker.noRights"), () => this.onChange(0), 0, <i
                        className={"fad fa-ban"}/>, !isPrivilege));
                    break;
                case 1:
                    result.push(this.renderItem(t("userRolePicker.readOnly"), () => this.onChange(1), 1, <i
                        className={"far fa-glasses-alt"}/>, !isPrivilege));
                    break;
                case 2:
                    result.push(this.renderItem(t("userRolePicker.fullAccess"), () => this.onChange(2), 2, <i
                        className={"fas fa-pencil"}/>, !isPrivilege));
                    break;
                case 3:
                    result.push(this.renderItem(t("userRolePicker.customAccess"), () => this.openModal(), 3, <i
                        className={"fas fa-clipboard-list-check"}/>, !isPrivilege));
                    break;
                default:
                    break;
            }
        });
        return result;
    };

    render() {
        const {header, options} = this.props;
        const optionButtons = this.getOptions(options);
        return (
            <div className={"role"}>
                <label>{header}</label>
                <div className={"options"}>
                    {optionButtons.map((o) => o)}
                </div>
                <hr/>
            </div>
        );
    }
}

Role.defaultProps = {
    onChange: () => {}
};

Role = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(Role);

export default withTranslation()(Role)
