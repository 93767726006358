import React from "react";
import {isMobile} from "../../utils/MobileUtils";
import animalsDB from "../../database/animalsDB";
import {addZerosToRfid} from "../../utils/AnimalsUtils";
import {addNotification} from "reapop";
import {pushLastScannedRFID, scannedRFID} from "../../actions/rfidActions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Drawer from "../basics/drawer/Drawer";
import Radio from "../basics/radio/Radio";
import Select from "../basics/select/Select";
import {AnimalTypes} from "../../constans/animalTypes";
import Button from "../basics/button/Button";
import buildingsDB from "../../database/buildingsDB";
import {Level} from "../../constans/levelTypes";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class TagReaderDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rfid: "",
            timeout: null,
            worktype: "documents",
            event: "insemination",
            show: false
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keyboardListener);
    }

    keyboardListener = event => {
        const {key} = event;
        if (!isNaN(+key)) {
            clearTimeout(this.state.timeout);
            let rfid = this.state.rfid + key;
            let timeout = setTimeout(() => {
                this.setState({
                    rfid: ""
                })
            }, isMobile() ? 500 : 100);
            this.setState({
                rfid, timeout
            });
        }
        if (key === "Enter" && this.state.rfid.length === 15) {
            switch (this.state.worktype) {
                case "documents":
                    this.handleDocuments(this.state.rfid);
                    break;
                case "feeding":
                    this.handleFeeding(this.state.rfid);
                    break;
                case "events":
                    this.handleEvents(this.state.rfid);
                    break;
                default:
                case "input":
                    console.warn("TODO");
                    break;
            }
            this.props.dispatch(scannedRFID(addZerosToRfid(this.state.rfid)));
            this.setState({
                show: false
            });
            event.preventDefault();
        }
    };

    handleDocuments(rfid) {
        const {farm, t} = this.props;
        let animal = animalsDB.getAnimalByRfid(addZerosToRfid(rfid), farm);
        if (!animal) {
            let stand = buildingsDB.getBoxBIDByRfid(addZerosToRfid(rfid));
            if (stand) {
                animal = animalsDB.getAllAnimalsForLocation(stand, Level.BOX)[0];
            }
        }
        if (animal) {
            this.props.history.push(`/${farm}/animalDocuments?RFID=${addZerosToRfid(rfid)}`);
            this.props.dispatch(pushLastScannedRFID({
                RFID: addZerosToRfid(rfid),
                animal,
                type: "documents",
                data: null
            }))
        } else {
            let notifi = {
                title: t("tagReaderDrawer.noAnimal"),
                message: i18next.t("tagReaderDrawer.noAnimalText", {number: this.state.rfid}),
                status: 'error',
                dismissible: true,
                dismissAfter: 5000
            };
            this.props.dispatch(addNotification(notifi));
        }
    }

    handleEvents(rfid) {
        const {farm, t} = this.props;
        let animal = animalsDB.getAnimalByRfid(addZerosToRfid(rfid), farm);
        if (!animal) {
            let stand = buildingsDB.getBoxBIDByRfid(addZerosToRfid(rfid));
            if (stand) {
                animal = animalsDB.getAllAnimalsForLocation(stand, Level.BOX)[0];
            }
        }
        if (animal) {
            if ((!this.props.location.pathname.includes("rfidEvents/insemination") || animal.AnimalKind !== AnimalTypes.BOAR) && !this.props.location.pathname.includes("/rfidEvents/transfer")) {
                const sowEvents = ["insemination", "usg", "noPregnancy", "parturition", "separationToPorkhouse", "separationToMommy", "mommy", "activeNipples"];
                if (animal.AnimalKind !== AnimalTypes.SOW && sowEvents.includes(this.state.event)) {
                    let notifi = {
                        title: t("tagReaderDrawer.wrongEvent"),
                        message: t("tagReaderDrawer.wrongEventText"),
                        status: 'error',
                        dismissible: true,
                        dismissAfter: 5000
                    };
                    this.props.dispatch(addNotification(notifi));
                } else {
                    this.props.history.push(`/${farm}/rfidEvents/${this.state.event}/${addZerosToRfid(this.state.rfid)}`);
                }
            }
        } else {
            if (!this.props.location.pathname.includes("rfidEvents/separationToPorkhouse") && !this.props.location.pathname.includes("/rfidEvents/transfer")) {
                let notifi = {
                    title: t("tagReaderDrawer.noAnimal"),
                    message: i18next.t("tagReaderDrawer.noAnimalText", {number: this.state.rfid}),
                    status: 'error',
                    dismissible: true,
                    dismissAfter: 5000
                };
                this.props.dispatch(addNotification(notifi));
            }
        }
    }

    handleFeeding(rfid) {
        const {farm} = this.props;
        let tmpRFID = addZerosToRfid(rfid);
        let animal = animalsDB.getAnimalByRfid(tmpRFID, farm);
        if (animal) {
            this.props.history.push(`/${farm}/feeding/${tmpRFID}`);
        } else {
            let location = buildingsDB.getLocationByRFID(tmpRFID);
            if (location) {
                if (location.BID) {
                    animal = animalsDB.getAllAnimalsForLocation(location, Level.BOX)[0];
                    this.props.history.push(`/${farm}/feeding/${tmpRFID}`);
                } else {
                    this.props.history.push(`/${farm}/feedingLocation/${location.BgID || location.SID || location.CID}`)
                }
            }
        }
        this.props.dispatch(pushLastScannedRFID({
            RFID: tmpRFID,
            animal,
            type: "feeding",
            data: null
        }))
    }

    onWorktypeChange(worktype) {
        this.setState({
            worktype
        })
    }

    onEventChange = value => {
        this.setState({
            event: value
        })
    };

    onDrawerMouseEnter = () => {
        this.setState({
            show: true
        })
    };

    onDrawerMouseLeave = () => {
        this.setState({
            show: false
        })
    };

    render() {
        const {worktype, event, show} = this.state;
        const {t} = this.props;
        return (
            <Drawer name={t("tagReaderDrawer.rfidReader")} show={show} onMouseLeave={this.onDrawerMouseLeave}
                    onMouseEnter={this.onDrawerMouseEnter}>
                <h6>{t("tagReaderDrawer.worktype")}</h6>
                <div>
                    <Radio label={t("tagReaderDrawer.documents")} checked={worktype === "documents"}
                           onChange={() => this.onWorktypeChange("documents")}/>
                </div>
                <div>
                    <Radio label={t("tagReaderDrawer.feeding")} checked={worktype === "feeding"}
                           onChange={() => this.onWorktypeChange("feeding")}/>
                </div>
                <div>
                    <div>
                        <Radio label={t("tagReaderDrawer.event")} checked={worktype === "events"}
                               onChange={() => this.onWorktypeChange("events")}/>
                    </div>
                    <div>
                        <Select value={event} options={[
                            {
                                name: t("insemination"),
                                value: "insemination"
                            },
                            {
                                name: t("usgTest"),
                                value: "usg"
                            },
                            {
                                name: t("noPregnancy"),
                                value: "noPregnancy"
                            },
                            {
                                name: t("birth"),
                                value: "parturition"
                            },
                            {
                                name: t("separationToPigletHouse"),
                                value: "separationToPorkhouse"
                            },
                            {
                                name: t("separationToMommy"),
                                value: "separationToMommy"
                            },
                            {
                                name: t("treatment"),
                                value: "grafting"
                            },
                            {
                                name: t("scheduleOfTreatment"),
                                value: "treatment"
                            },
                            {
                                name: t("allPiglets"),
                                value: "fallPiglets"
                            },
                            {
                                name: t("transfer"),
                                value: "transfer"
                            },
                            {
                                name: t("fall"),
                                value: "fall"
                            },
                            {
                                name: t("sale"),
                                value: "sell"
                            },
                            {
                                name: t("selection"),
                                value: "selection"
                            },
                            {
                                name: t("mommy"),
                                value: "mommy"
                            },
                            {
                                name: t("weighting"),
                                value: "weighting"
                            },
                            {
                                name: t("activeNipples"),
                                value: "activeNipples"
                            }
                        ]} renderInPortal={false} onChange={this.onEventChange} clearButton={false}
                                disabled={worktype !== "events"}/>
                    </div>
                </div>
                <div>
                    <Radio label={t("tagReaderDrawer.insert")} checked={worktype === "input"}
                           onChange={() => this.onWorktypeChange("input")}/>
                </div>
                <Link to={`/${this.props.farm}/lastScannedRFID`}>
                    <Button>
                        {t("tagReaderDrawer.lastScanned")}
                    </Button>
                </Link>
            </Drawer>
        );
    }

}

TagReaderDrawer = connect(state => ({
    farm: state.location.farm,
}))(TagReaderDrawer);

TagReaderDrawer = withRouter(TagReaderDrawer);

export default withTranslation(TagReaderDrawer);