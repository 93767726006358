import React from "react";
import PropTypes from "prop-types";
import Card from "../basics/card/Card";
import moment from "moment";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

export class TransactionMobileSelectedItem extends React.Component {

    getError() {
        const {object: {processorResponseCode}, t} = this.props;
        if (+processorResponseCode > 2100) {
            return t("transactions.errors")[2101];
        }
        return t("transactions.errors")[processorResponseCode];
    }

    render() {
        const {object, t} = this.props;
        return (
            <div>
                {
                    object &&
                    <Card>
                        <div className="transaction-item">
                            <div className="font-weight-bold">{t("date")}</div>
                            <div>{moment(object.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                        </div>
                        <div className="transaction-item">
                            <div className="font-weight-bold">{t("sum")}</div>
                            <div>{object.amount} {object.currencyIsoCode}</div>
                        </div>
                        <div className="transaction-item">
                            <div className="font-weight-bold">{t("cardNumber")}</div>
                            <div>{object.creditCard.maskedNumber}</div>
                        </div>
                        <div className="transaction-item">
                            <div className="font-weight-bold">{t("farmSettings.transactionHistory.period")}</div>
                            <div>{moment(object.subscription.billingPeriodStartDate).format("DD.MM.YYYY")} - {moment(object.subscription.billingPeriodEndDate).format("DD.MM.YYYY")}</div>
                        </div>
                        <div className="transaction-item">
                            <div className="font-weight-bold">{t("status")}</div>
                            <div>{object.status === "settled" ? "Zaksięgowana" : this.getError()}</div>
                        </div>
                    </Card>
                }
            </div>
        );
    }

}

TransactionMobileSelectedItem.propTypes = {
    show: PropTypes.bool,
    object: PropTypes.object,
    onHide: PropTypes.func
};

TransactionMobileSelectedItem = connect(state => ({
}))(TransactionMobileSelectedItem);

export default withTranslation()(TransactionMobileSelectedItem);