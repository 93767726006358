import React, {Component} from "react";
import {connect} from "react-redux";
import "./_technicalBreakComponent.scss";
import moment from "moment-timezone";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {isMobile} from "../../utils/MobileUtils";

class TechnicalBreakComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getClassName() {
        let className = "technical-break-banner"
        if (isMobile()) className += " mobile";
        return className;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {maintenance} = this.props;
        const tz = moment.tz.guess();
        if (maintenance.futureMaintenance && !_.isEmpty(maintenance.futureMaintenance) && !_.isEqual(maintenance, prevProps.maintenance)) {
            this.setState({
                DateStart: moment.tz(maintenance.futureMaintenance.DtaStartTime, tz).format("DD-MM-YYYY (H:mm)"),
                DateEnd: moment.tz(maintenance.futureMaintenance.DtaEndTime, tz).format("DD-MM-YYYY (H:mm)"),
                show: true
            })
        }
    }


    render() {
        const {t} = this.props;

        const {DateStart, DateEnd, show} = this.state;
        return (
            <>

                {show && DateStart && DateEnd &&
                <div className={this.getClassName()}>
                    {t("technicalBreakComponent.technicalBreak", {from: DateStart, to: DateEnd})}
                </div>
                }
            </>
        )
    }
}


TechnicalBreakComponent = connect(state => ({
    maintenance: state.maintenance
}))(TechnicalBreakComponent);

export default withTranslation("login")(TechnicalBreakComponent);
